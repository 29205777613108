import Tagify from '@yaireo/tagify'
import {} from '@yaireo/tagify/dist/tagify.css'
import {} from './custom.sass'

export default function tagify(tags) {
  var input = document.querySelector('#ttags'),
      tagifyInstance = new Tagify(input, {
          enforceWhitelist : true,
          whitelist        : tags.map(t => t.text).sort(),
          dropdown: {enabled: 1}
          // callbacks        : {
          //     add    : console.log,  // callback when adding a tag
          //     remove : console.log   // callback when removing a tag
          // }
      });

      tagifyInstance.on("add", function(arg){
        const htmlClass = "tagify"; //tags.find(i => i.text === arg.detail.value).html.class;
        arg.detail.tag.classList.add(htmlClass)
      })
  return tagifyInstance;
}
