import './application.sass'

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
$ = jquery;

import {} from 'jquery-ujs'
import autosize from 'autosize'
import cloud from './jqcloud/index.js'
import menu from './menu.js'
import tagify from './tagify'
// import _prism from './prism.js'
import Prism from 'prismjs';

Prism.hooks.add("before-highlight", function (env) {
  env.code = env.element.innerText;
});
// Prism.highlightElement(code);

$(function(){
  autosize($('.js-autosize'));
  menu();
  cloud();
  const $cloud = $('#cloud');
  if ($cloud.length) {
    window.tagifyInstance = tagify($cloud.data('tags'));
  }

  $(".js-team").on('click', '.js-team-push', function(e){
    e.preventDefault();
    var bias = $(e.currentTarget).closest('.team__item')[$(e.currentTarget).hasClass('_prev') ? 'prev' : 'next']().position().left;
    $(e.delegateTarget).stop().animate({scrollLeft: bias}, 300, 'swing');

  });

  $('.js-ajaxForm').on('ajax:success', function(e) {
    const $form = $(e.currentTarget);
    $form.get(0).reset();
    if ($form.find('#ttags').length) {
      tagifyInstance.removeAllTags()
    }
    $form.find(".js-ajax-form-success").show().delay(2000).fadeOut();
  });
});
