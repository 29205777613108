export default function menu(){
  $(".js-menu").on('click', function(e){
    e.stopPropagation()
  })
  .on('click', '.js-menu-close, .js-menu-link', function(e){
    $(e.delegateTarget).removeClass('is-open')
  });
  $('.js-menu-open').click(function(e){
    e.stopPropagation()
    $('.js-menu').addClass('is-open')
    $(document).one("click", function(){
      $('.js-menu').removeClass('is-open')
    });
  });
}
