import {} from './jqcloud.sass'
import {} from './jqcloud.js'

export default function cloud() {
  $('#cloud').map(function(){
    const tags = $(this).data('tags');
    const $activeSectionBox = $(".expertise");
    const $tagifyWrap = $(".js-tagify--wrap");

    $tagifyWrap.on({
      focusin() {
        $(this).addClass('_focused')
      },
      focusout() {
        $(this).removeClass('_focused')
      },
      click() {
        $(this).find('.tagify__input').focus()
      }
    })

    $("#cloud").on({
      mouseenter() {
        $activeSectionBox.addClass("_focused")
        $tagifyWrap.addClass('_focused')
      },
      mouseleave() {
        $activeSectionBox.removeClass("_focused")
        if (!$(":focus", $tagifyWrap).length) {
          $tagifyWrap.removeClass('_focused')
        }
      }
    })

    tags.forEach(function(item){
      item.link = '#';
      const $activeSection = $activeSectionBox.find(".expertise__item").filter("._" + item.html.class.replace(/^_bg-/, ''));

      item.handlers = {
        click() {
          event.preventDefault();
          tagifyInstance.addTags([{value: this.innerText, class: item.html.class}]);
        },
        mouseenter() {
          $activeSection.addClass("_active");
          const $a = $(this).find('a');
          $a.css({'box-shadow': `0 5px 10px ${getComputedStyle($a.get(0)).backgroundColor}`})
        },
        mouseleave() {
          $activeSection.removeClass("_active");
          $(this).find('a').css({'box-shadow': ""})
        }
      }
    });

    $(this).jQCloud(tags, {
      classPattern: '_w{n}',
      autoResize: true,
      height: 300,
      steps: 4
    });
  });
}
